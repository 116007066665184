<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-card>
        <v-card-text>
          <v-col cols="12" class="subheading">Identify hazard</v-col>
          <v-col cols="12">
            <v-select
              v-model="vm.area"
              :items="areas"
              attach
              label="Area"
              v-validate="'required'"
              data-vv-as="area"
              name="area"
              item-text="title"
              return-object
              :error-messages="errors.collect('area')"
            ></v-select>
          </v-col>

          <v-col cols="12" v-if="vm.area">
            <v-select
              v-model="vm.subArea"
              :items="vm.area.items"
              attach
              label="Sub Area"
              v-validate="'required'"
              data-vv-as="subArea"
              name="subArea"
              :error-messages="errors.collect('subArea')"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea
              filled
              v-model="vm.description"
              label="Description of the hazard or risk"
              v-validate="'required'"
              rows="1"
              data-vv-as="description"
              name="description"
              :error-messages="errors.collect('description')"
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="subtitle-1 pt-4">Assess the level of risk</v-col>
          <v-col class="pb-4" cols="12">
            <v-select
              v-model="vm.likelihood"
              @change="assessRiskLevel()"
              :items="likelihoods"
              item-text="title"
              attach
              label="Likelihood"
              v-validate="'required'"
              data-vv-as="likelihood"
              name="likelihood"
              return-object
              :error-messages="errors.collect('likelihood')"
            >
              <template v-slot:item="{ item, index }">
                <span>
                  {{ item.title}}
                  <div class="caption font-italic">{{item.description}}</div>
                </span>
              </template>
            </v-select>
            <div
              class="caption font-italic"
              style="margin-top:-10px"
              v-if="vm.likelihood"
            >{{vm.likelihood.description}}</div>
          </v-col>
          <v-col class="pb-4" cols="12">
            <v-select
              v-model="vm.consequence"
              @change="assessRiskLevel()"
              :items="consequences"
              attach
              label="Consequence"
              item-text="title"
              v-validate="'required'"
              data-vv-as="consequence"
              name="consequence"
              return-object
              :error-messages="errors.collect('consequence')"
            >
              <template v-slot:item="{ item, index }">
                <span>
                  {{ item.title}}
                  <div class="caption font-italic">{{item.description}}</div>
                </span>
              </template>
            </v-select>
            <div
              class="caption font-italic"
              style="margin-top:-10px"
              v-if="vm.consequence"
            >{{vm.consequence.description}}</div>
          </v-col>
          <v-col v-if="vm.assessedRiskLevel">
            <v-chip
              :color="vm.assessedRiskLevel.color"
              :text-color="vm.assessedRiskLevel.textColor"
            >{{vm.assessedRiskLevel.level}}</v-chip>
            {{vm.assessedRiskLevel.description}}
            <br />
            {{vm.assessedRiskLevel.actions}}
          </v-col>
          <v-col cols="12" class="subtitle-1 pt-4">Control the risk</v-col>
          <v-col cols="12">
            <div>Note control measures should be implemented in accordance with the preferred hierarchy of control. If lower level controls (such as administration or PPE) are to be implemented with out higher level controls. It is important that the reasons are explained.</div>
          </v-col>
          <v-col class="pb-4" cols="12">
            <v-textarea
              filled
              v-model="vm.controlMeasures"
              label="Control measures"
              v-validate="'required'"
              rows="1"
              data-vv-as="controlMeasures"
              persistent-hint
              hint="(Note: if only administration or PPE controls are used. please explain why)"
              name="controlMeasures"
              :error-messages="errors.collect('controlMeasures')"
            ></v-textarea>
          </v-col>
          <v-col class="pt-4" cols="12">
            <v-textarea
              filled
              v-model="vm.other"
              label="Other information"
              rows="1"
              data-vv-as="other"
              name="other"
              :error-messages="errors.collect('other')"
            ></v-textarea>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as riskAssessmentService from "../../../services/individualRiskAssessmentService";
import * as firebaseEmailService from "../../../services/firebaseEmailService";
import * as notificationService from "../../../services/notificationService";

Vue.use(VeeValidate);

export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "participant-details",
  data: () => ({
    e1: 0,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    emails: [],
    vm: {},
    form: {},
    valid: true,

    programs: ["Day Services", "Another Program", "Another Program 3"],
    risk: [
      {
        level: "Low",
        description:
          "If an incident were to occur, there would be little likeihood that an injury would result",
        actions: "Undertake the activity with the existing controls in place",
        color: "green",
        textColor: "white"
      },
      {
        level: "Medium",
        description:
          "If an incident were to occur, there would be some chance that an injury would result",
        actions: "Additional controls may be needed",
        color: "yellow",
        textColor: "black"
      },
      {
        level: "High",
        description:
          "If an incident were to occur, it would be likely that an injury requiring medical treatment would result",
        actions:
          "Controls will need to be in place before the activity is undertaken",
        color: "orange",
        textColor: "white"
      },
      {
        level: "Extreame",
        description:
          "If an incident were to occur, it would be likely that a permanent, debilitating injury or death would result",
        actions:
          "Consider alternatives to doing the activity. Significant control measures will need to be implemented to ensure safety.",
        color: "red",
        textColor: "white"
      }
    ],
    matrix: [
      { likelihood: 5, concequence: 1, risk: "Medium" },
      { likelihood: 5, concequence: 2, risk: "Medium" },
      { likelihood: 5, concequence: 3, risk: "High" },
      { likelihood: 5, concequence: 4, risk: "Extreame" },
      { likelihood: 5, concequence: 5, risk: "Extreame" },

      { likelihood: 4, concequence: 1, risk: "Low" },
      { likelihood: 4, concequence: 2, risk: "Medium" },
      { likelihood: 4, concequence: 3, risk: "High" },
      { likelihood: 4, concequence: 4, risk: "High" },
      { likelihood: 4, concequence: 5, risk: "Extreame" },

      { likelihood: 3, concequence: 1, risk: "Low" },
      { likelihood: 3, concequence: 2, risk: "Medium" },
      { likelihood: 3, concequence: 3, risk: "Medium" },
      { likelihood: 3, concequence: 4, risk: "High" },
      { likelihood: 3, concequence: 5, risk: "High" },

      { likelihood: 2, concequence: 1, risk: "Low" },
      { likelihood: 2, concequence: 2, risk: "Low" },
      { likelihood: 2, concequence: 3, risk: "Medium" },
      { likelihood: 2, concequence: 4, risk: "Medium" },
      { likelihood: 2, concequence: 5, risk: "High" },

      { likelihood: 1, concequence: 1, risk: "Low" },
      { likelihood: 1, concequence: 2, risk: "Low" },
      { likelihood: 1, concequence: 3, risk: "Low" },
      { likelihood: 1, concequence: 4, risk: "Low" },
      { likelihood: 1, concequence: 5, risk: "Medium" }
    ],
    consequences: [
      {
        id: 1,
        title: "Insignificant",
        description: "No treatment required"
      },
      {
        id: 2,
        title: "Minor",
        description:
          "Minor injury requiring first aid treatment (e.g. minor cuts, bruises, bumps)"
      },
      {
        id: 3,
        title: "Moderate",
        description: "Injury requiring medical treatment or lost time"
      },
      {
        id: 4,
        title: "Major",
        description:
          "Serious injury (injuries) requring specialist medical treatment or hospitalisation."
      },
      {
        id: 5,
        title: "Critical",
        description:
          "Loss of life, permanent disability or multiple serious injuries"
      }
    ],
    likelihoods: [
      {
        id: 1,
        title: "Rare",
        description: "Will only occur in exceptional circumstances"
      },
      {
        id: 2,
        title: "Unlikely",
        description:
          "Not likely to occur with in the foreeseeable future, or within the project lifecycle"
      },
      {
        id: 3,
        title: "Possibile",
        description:
          "May occur with in the foreeseeable future, or within the project lifecycle"
      },
      {
        id: 4,
        title: "Likely",
        description:
          "Likely to occur withing the foreseeable future, or within the project lifecycle"
      },
      {
        id: 5,
        title: "Almost certain",
        description:
          "Almost certain to occur within the forseable future, or within the project lifecycle"
      }
    ],
    areas: [
      {
        title: "Chemical",
        items: [
          "Hazardous chemicals - Fumes",
          "Hazardous chemicals - Gases",
          "Hazardous chemicals - liquid",
          "Hazardous chemicals - Mist",
          "Hazardous chemicals - Solids",
          "Non hazardous chemicals"
        ]
      },
      {
        title: "Environmental",
        items: [
          "Acoustic sounds/Noise",
          "Adequate access",
          "Air conditioning",
          "Animal/Insects",
          "Cold",
          "Confined spaces",
          "Ground surfaces",
          "Heat",
          "Indoor Environment",
          "Outdoor Environment",
          "Sun exposure",
          "Temperature",
          "Traffic - People",
          "Traffic - Vehicles",
          "Water",
          "Weather",
          "Wind"
        ]
      },
      {
        title: "Facility",
        items: [
          "Buildings and Fixtures",
          "Driveway and footpaths",
          "Furniture",
          "Playground and Equipment",
          "Swimming pool",
          "Workshops"
        ]
      },
      {
        title: "Health and safety",
        items: [
          "Bites and stings",
          "Dehydration",
          "Food",
          "Food handling",
          "Human tissue or fluids",
          "Poisoning",
          "Violence",
          "Virus or disease"
        ]
      },
      {
        title: "Individual",
        items: [
          "Asthma plan",
          "Behaviours of concern",
          "Behaviours Support Plan",
          "Communication Aids",
          "Health care plan",
          "Other plan",
          "Other medical conditions",
          "Non Verbal",
          "Other",
          "Physical limitations",
          "Walker",
          "Wheelchair"
        ]
      },
      {
        title: "Kinetic energy",
        items: [
          "Body hit by Moving Objects",
          "Body hit by falling objects",
          "Body bumping in to moving objects",
          "Body bumping in to fixed objects",
          "Fall, trips and slips"
        ]
      },

      {
        title: "Mechanical",
        items: [
          "Electrical",
          "Mobile and fixed plant",
          "Non powered equipment",
          "Powered equipment",
          "Sharp objects",
          "Vehicles and Trailers",
          "Vehicles - other"
        ]
      },

      {
        title: "Manual handling",
        items: [
          "Bending",
          "Lifting and carrying",
          "Pushing and pulling",
          "Reaching and over stretching",
          "Repetative movement",
          "Sitting",
          "Working at heights"
        ]
      }
    ],
    checkbox: false
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentAssessment() {
      return this.$store.getters.currentAssessment;
    },
    currentRisk() {
      return this.$store.getters.currentRisk;
    },
    user() {
      return this.$store.getters.user;
    }
  },

  methods: {
    icon() {
      return this.locked ? "mdi-lock-open" : "mdi-lock";
    },
    async init() {
      if (
        this.$store.getters.currentRisk !== undefined &&
        this.$store.getters.currentRisk !== null
      ) {
        this.vm = this.$store.getters.currentRisk;
      }
       await notificationService.getRiskById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.emails = temp.Emails;
        console.log(this.emails);
      });
    },
    save() {
      let me = this;
      me.vm.modifiedOn = new Date();
      me.vm.modifiedBy = me.user;
      if (me.vm.id == undefined) {
        me.vm.createdOn = new Date();
        me.vm.createdBy = me.user;
      }
      me.vm.isDeleted = false;
      riskAssessmentService
        .saveRisk(me.currentOrganisation.id, me.currentCase.id, me.vm)
        .then(ref => {
          if (ref != undefined) {
            me.vm.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Individual risk assessment saved",
            color: "primary"
          });
          me.$emit("risk-assessment-added", me.vm);
          this.$router.push("/individual-risk-assessments");
        });
    },
    cancel() {
      this.$router.push("/individual-risk-assessments");
    },
    assessRiskLevel() {
      this.vm.assessedRiskLevel = null;
      if (this.vm.likelihood == undefined || this.vm.consequence == undefined) {
        return;
      }
      var me = this;
      const match = this.matrix.find(x => {
        return (
          x.likelihood === me.vm.likelihood.id &&
          x.concequence === me.vm.consequence.id
        );
      });

      if (match != null) {
        this.vm.assessedRiskLevel = this.risk.find(x => {
          return x.level === match.risk;
        });
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendEmail();  
          this.save();
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    sendEmail(){
         let me=this; 

            let participantFullname=me.currentCase.participantDetails.firstname+" "+me.currentCase.participantDetails.lastname;
             
             let fromID=me.user.email;
             let fromName=me.user.displayName
             let toID=this.emails;
             let subject="Omar notifications - new risk assessment";
             console.log(me.vm.area);
             let text= participantFullname+" has new "+ me.vm.area.title +" risk assessment";
            
             let sendEmail="Do you want to send an Email?";
             
             if (confirm(sendEmail) == true) {
                   firebaseEmailService.sendRisk(fromID, fromName, toID, subject, text);
                 
              } else {
                  console.log("not sent");
              } 

      }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

